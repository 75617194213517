import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText"

gsap.registerPlugin(ScrollTrigger, SplitText);

ScrollTrigger.defaults({
  // Defaults are used by all ScrollTriggers
  //events: onEnter onLeave onEnterBack onLeaveBack
  toggleActions: "restart pause resume pause",
  //options: play, pause, resume, reset, restart, complete, reverse,none
  // markers: true // Easily remove markers for production
});

const logo = document.querySelectorAll(".logo-link .alloy-logo");

logo.forEach(function (container) {
  const timelineLogo = gsap.timeline();
  timelineLogo
    .from(container.querySelectorAll(["path", "rect"]), {
      // xy: random,
      x: "random(-100, 100)",
      opacity: 0,
      stagger: {
        amount: 0.8,
        from: "random"
      }
    });
});
//------------------------------------------------------//
// 🎈 Logo
//------------------------------------------------------//
document.querySelectorAll(".ACF-logo").forEach(function (container) {

  const timeline = gsap.timeline({
    scrollTrigger: {
      id: "LOGO", // Custom label to the marker
      trigger: container, // What element triggers the scroll
      scrub: false, // Add a small delay of scrolling and animation. `true` is direct
      start: "top center", // Can be top, center, bottom
      end: "+=100% center", // Can be top, center, bottom
      // pin: true,
    }
  });

  timeline.from(container.querySelectorAll(["path", "rect"]), {
    // xy: random,
    x: gsap.utils.random(-100, 100),
    opacity: 0,
    repeatRefresh: true,
    stagger: {
      amount: 0.8,
      // each: 0.2,
      from: "random"
    }
  });

});
// END 🎈 Logo  -------------------------------------//


//------------------------------------------------------//
// ⭐️ Kenmerken
//------------------------------------------------------//
function textPop(item) {
  const timeline = gsap.timeline();

  timeline
    // .to(".alloy-kenmerken .content .item", {scale: 0 })
    .from(item, {
      y: 100,
      autoAlpha: 0,
      transformOrigin: "50% 50%",
      duration: 0.6,
      ease: "back.out(2)"
    })
    .to(
      ".alloy-kenmerken svg g", {
      // x: "-=10",
      y: "-=50",
      yoyo: true,
      ease: CustomEase.create(
        "custom",
        "M0,0 C0,0.444 0.204,1 0.51,1 0.802,1 1,0.464 1,0 "
      ),
      stagger: {
        from: "center",
        amount: 0.6,
      }
    },
      "-=0.6"
    )
    .to(item, {
      y: -100,
      opacity: 0,
    }, "+=2");
  return timeline;
}

if (document.querySelector('.ACF-kenmerken-type-animation')) {

  const timelineText = gsap.timeline({ repeat: -1 });
  document.querySelectorAll(".ACF-kenmerken-type-animation .content .item").forEach(function (item) {

    timelineText.add(textPop(item));

  });

  const timeline = gsap.timeline(
    // { repeat: -1, repeatDelay: 1 }
  );

  const timelineKenmerken = gsap.timeline({
    repeat: -1,
    scrollTrigger: {
      id: "KENMERKEN", // Custom label to the marker
      trigger: ".ACF-kenmerken-type-animation", // What element triggers the scroll
      // scrub: 1, // Add a small delay of scrolling and animation. `true` is direct
      start: "top center", // Can be top, center, bottom
      end: "+=100% 50px", // Can be top, center, bottom
      pin: false, // Pin the element true or false
    }
  });

  timelineKenmerken
    .set(".ACF-kenmerken-type-animation svg g", {
      rotation: gsap.utils.random(-90, 90, 5, true),
      transformOrigin: "center center"
    })
    .from(".ACF-kenmerken-type-animation svg g .top", {
      // y: 442,
      // x: 884,
      scaleY: 0,
      // autoAlpha: 0,
      duration: 0.6,
      transformOrigin: "bottom center",
      // ease: "back.out(1.5)",
      // stagger: distributeByPosition({
      //   from: "center",
      //   amount: 0.6
      // })
    })
    .add(timelineText);
}

// END timeline
// END ⭐️ Kenmerken -------------------------------------//

//------------------------------------------------------//
// Parallex items
//------------------------------------------------------//
document.querySelectorAll(".parallaxContainer").forEach(function (container) {

  const item = container.querySelectorAll(".parallaxItem");

  const timeline = gsap.timeline({
    scrollTrigger: {
      id: "PARALLAX", // Custom label to the marker
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom-=400px", // Can be top, center, bottom
      end: "+=100% top", // Can be top, center, bottom
      // pin: true,
    }
  });

  timeline.to(item, {
    y: gsap.utils.random(-300, -700, 100, true),
    // stagger: {
    //   each: 0.2
    // }
  });

});
// END Parallex items  -------------------------------------//

//------------------------------------------------------//
// Animate manifest
//------------------------------------------------------//
// var animateText = function (text) {
//   var textSplit = SplitText.create('.animateText', { type: "chars,words" });
//   return gsap.from(textSplit.chars, {
//     duration: 0.055,
//     visibility: "hidden",
//     stagger: 0.05
//   });
// };
//
// animateText('.animateText');

document.querySelectorAll(".ACF-montessori_manifest").forEach(function (container) {


  const timeline = gsap.timeline({
    scrollTrigger: {
      id: "MANIFEST", // Custom label to the marker
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top center", // Can be top, center, bottom
      end: "+=100% center", // Can be top, center, bottom
      // pin: true,
    }
  });

  var textSplit = SplitText.create('.animateText', { type: "chars,words" });

  timeline.from(textSplit.words, {
    duration: 0.2,
    visibility: "hidden",
    stagger: 0.2
  });
});
//------------------------------------------------------//
// END Animate manifest
//------------------------------------------------------//
//------------------------------------------------------//
// ✨ Parallax elements
//------------------------------------------------------//

// 📏 Browser height logic -----------------------------------------//
const pageHeight = Math.max(
  document.body.scrollHeight, document.documentElement.scrollHeight,
  document.body.offsetHeight, document.documentElement.offsetHeight,
  document.body.clientHeight, document.documentElement.clientHeight
);
const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeigh;

const parallaxContainer = document.querySelector('#parallaxContainer');
const parallaxItems = parallaxContainer.querySelectorAll('img');

parallaxContainer.style.height = pageHeight + 'px'; // Set container to page height

parallaxItems.forEach(function (item) {

  const timeline = gsap.timeline({
    scrollTrigger: {
      id: "BLOCK", // Custom label to the marker
      trigger: item, // What element triggers the scroll
      scrub: true, // Add a small delay of scrolling and animation. `true` is direct
      start: "top bottom", // Can be top, center, bottom 
      end: "+=100% top", // Can be top, center, bottom 
    }
  });

  timeline.to(item, {
    y: function (i, target) { return -windowHeight * target.dataset.speed; },
    roundProps: "y",
    ease: 'none',
  });

});

  // END ✨ Parallax elements -------------------------------------//
